import React from "react";
import { Container } from "react-bootstrap";
import "../styles/Footer.css";

const Footer = () => (
  <footer className="footer mt-auto py-3 bg-light">
    <Container>
      <span className="text-muted">© 2023 Student Visa Dashboard</span>
    </Container>
  </footer>
);

export default Footer;
