/**
 * Login component that handles user authentication
 * Features a responsive design with a background image section and login form
 * Uses React Bootstrap for UI components and Framer Motion for animations
 */

import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import "../styles/login.css";
import config from "../config";

/**
 * Login component that manages the authentication form and API interaction
 * @param {Function} onLogin - Callback function to handle successful login
 */
const Login = ({ onLogin }) => {
  // State management for form data, validation errors, and loading state
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Handles form input changes and updates the form state
   * @param {Event} e - The input change event
   */
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'rememberMe' ? checked : value
    }));
  };

  /**
   * Handles form submission and authentication API call
   * Includes error handling and loading state management
   * @param {Event} e - The form submission event
   */
  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});

    const apiUrl = `${config.API_URL}/login`;
    console.log('Config API_URL:', config.API_URL);
    console.log('Full API URL:', apiUrl);

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
          remember: formData.rememberMe
        }),
        credentials: 'include'
      });

      const data = await response.json();

      if (response.ok) {
        onLogin(data);
      } else {
        setErrors({ general: data.error || 'Login failed. Please try again.' });
      }
    } catch (error) {
      let errorMessage = 'Network error. Please try again.';
      if (error instanceof TypeError && error.message === 'Failed to fetch') {
        errorMessage = 'Unable to connect to the server. Please check if the backend service is running.';
      }
      setErrors({ general: errorMessage });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container fluid className="login-container p-0">
      <Row className="g-0 h-100">
        {/* Background image section - only visible on large screens */}
        <Col lg={7} className="d-none d-lg-block background-section">
          <div className="background-overlay"></div>
        </Col>

        {/* Login form section */}
        <Col lg={5} className="form-section">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="login-form-wrapper"
          >
            <div className="login-form">
              <h1 className="mb-4">Welcome back!</h1>
              
              {/* Error message display */}
              {errors.general && (
                <div className="alert alert-danger" role="alert">
                  {errors.general}
                </div>
              )}

              <Form onSubmit={handleLogin}>
                {/* Email input field */}
                <Form.Group className="mb-3" controlId="email">
                  <Form.Control
                    type="email"
                    placeholder="Email address"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className={`form-input ${errors.email ? 'is-invalid' : ''}`}
                  />
                  {errors.email && (
                    <div className="text-danger small mt-1">
                      {errors.email}
                    </div>
                  )}
                </Form.Group>

                {/* Password input field */}
                <Form.Group className="mb-3" controlId="password">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    className={`form-input ${errors.password ? 'is-invalid' : ''}`}
                  />
                  {errors.password && (
                    <div className="text-danger small mt-1">
                      {errors.password}
                    </div>
                  )}
                </Form.Group>

                {/* Remember me checkbox */}
                <Form.Group className="mb-4" controlId="rememberMe">
                  <Form.Check
                    type="checkbox"
                    label="Remember password"
                    name="rememberMe"
                    checked={formData.rememberMe}
                    onChange={handleChange}
                    className="remember-me"
                  />
                </Form.Group>

                {/* Submit button with animation */}
                <motion.div
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.99 }}
                >
                  <Button
                    type="submit"
                    className="sign-in-button w-100"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Signing in...' : 'Sign in'}
                  </Button>
                </motion.div>

                {/* Forgot password link */}
                <div className="text-center mt-3">
                  <Button
                    variant="link"
                    className="forgot-password-link"
                  >
                    Forgot password?
                  </Button>
                </div>
              </Form>
            </div>
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
