import React, { useState, useEffect } from "react";
import { Container, Table, Button, Form, Modal } from "react-bootstrap";
import AppNavbar from "./Navbar";
import Footer from "./Footer";
import "../styles/Dashboard.css";
import config from "../config";

const AdminPanel = ({ user, onLogout }) => {
  const [users, setUsers] = useState([]);
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showChangeOwnerModal, setShowChangeOwnerModal] = useState(false);
  const [showEditStudentModal, setShowEditStudentModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [editPermissions, setEditPermissions] = useState([]);
  const [activeSection, setActiveSection] = useState("users");
  const [currentUser] = useState(user);
  const [createUserForm, setCreateUserForm] = useState({
    name: "",
    email: "",
    password: "",
    phone_number: "",
    is_admin: false,
    permissions: [],
  });
  const [editStudentForm, setEditStudentForm] = useState({
    name: "",
    travel_doc_no: "",
    nationality: "",
  });
  const [createError, setCreateError] = useState("");
  const [newOwnerId, setNewOwnerId] = useState("");
  const [universities, setUniversities] = useState([]);
  const [showUniversityModal, setShowUniversityModal] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [universityForm, setUniversityForm] = useState({
    name: "",
    country: "",
    address: "",
    website: ""
  });

  const availablePermissions = [
    { id: "edit_students", name: "Edit Students" },
    { id: "add_student", name: "Add Students" },
    { id: "view_all_students", name: "View All Students" },
    { id: "invoice_access", name: "Invoice Access" },
  ];

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${config.API_URL}/users`, {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStudents = async () => {
    try {
      const response = await fetch(`${config.API_URL}/admin/students`, {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setStudents(data);
      }
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  const fetchUniversities = async () => {
    try {
      const response = await fetch(`${config.API_URL}/admin/universities`, {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setUniversities(data);
      }
    } catch (error) {
      console.error("Error fetching universities:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchStudents();
    fetchUniversities();
  }, []);

  const handleEditPermissions = (user) => {
    setSelectedUser(user);
    setEditPermissions(user.permissions || []);
    setShowEditModal(true);
  };

  const handlePermissionChange = (permission) => {
    setEditPermissions((prev) => {
      if (prev.includes(permission)) {
        return prev.filter((p) => p !== permission);
      }
      return [...prev, permission];
    });
  };

  const handleSavePermissions = async () => {
    try {
      const response = await fetch(
        `${config.API_URL}/users/${selectedUser.id}/permissions`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            permissions: editPermissions,
            is_admin: selectedUser.is_admin,
          }),
        }
      );

      if (response.ok) {
        await fetchUsers();
        setShowEditModal(false);
      }
    } catch (error) {
      console.error("Error updating permissions:", error);
    }
  };

  const handleAdminStatusChange = async (userId, newStatus) => {
    try {
      const response = await fetch(
        `${config.API_URL}/users/${userId}/admin-status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ is_admin: newStatus }),
        }
      );

      if (response.ok) {
        await fetchUsers();
      }
    } catch (error) {
      console.error("Error updating admin status:", error);
    }
  };

  const handleDeleteUser = async (userId) => {
    if (!window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}/users/${userId}`, {
        method: 'DELETE',
        credentials: 'include'
      });

      const data = await response.json();

      if (response.ok) {
        await fetchUsers();
      } else {
        alert(data.error || 'Failed to delete user');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      alert('An error occurred while deleting the user');
    }
  };

  const handleCreateUser = async () => {
    try {
      const response = await fetch(`${config.API_URL}/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(createUserForm),
      });

      const data = await response.json();

      if (response.ok) {
        await fetchUsers();
        setShowCreateModal(false);
        setCreateUserForm({
          name: "",
          email: "",
          password: "",
          phone_number: "",
          is_admin: false,
          permissions: [],
        });
        setCreateError("");
      } else {
        setCreateError(data.error || "Failed to create user");
      }
    } catch (error) {
      console.error("Error creating user:", error);
      setCreateError("An error occurred while creating the user");
    }
  };

  const handleCreateFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCreateUserForm((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCreatePermissionChange = (permission) => {
    setCreateUserForm((prev) => ({
      ...prev,
      permissions: prev.permissions.includes(permission)
        ? prev.permissions.filter((p) => p !== permission)
        : [...prev.permissions, permission],
    }));
  };

  const handleChangeOwner = async () => {
    try {
      const response = await fetch(
        `${config.API_URL}/admin/students/${selectedStudent.id}/change-owner`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ user_id: parseInt(newOwnerId) }),
        }
      );

      if (response.ok) {
        await fetchStudents();
        setShowChangeOwnerModal(false);
        setSelectedStudent(null);
        setNewOwnerId("");
      }
    } catch (error) {
      console.error("Error changing student owner:", error);
    }
  };

  const handleEditStudent = (student) => {
    setSelectedStudent(student);
    setEditStudentForm({
      name: student.name,
      travel_doc_no: student.travel_doc_no,
      nationality: student.nationality,
    });
    setShowEditStudentModal(true);
  };

  const handleEditStudentSubmit = async () => {
    try {
      const response = await fetch(
        `${config.API_URL}/admin/students/${selectedStudent.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(editStudentForm),
        }
      );

      if (response.ok) {
        await fetchStudents();
        setShowEditStudentModal(false);
        setSelectedStudent(null);
        setEditStudentForm({
          name: "",
          travel_doc_no: "",
          nationality: "",
        });
      }
    } catch (error) {
      console.error("Error updating student:", error);
    }
  };

  const handleDeleteStudent = async (student) => {
    if (
      window.confirm(
        "Are you sure you want to delete this student? This action cannot be undone."
      )
    ) {
      try {
        const response = await fetch(
          `${config.API_URL}/admin/students/${student.id}`,
          {
            method: "DELETE",
            credentials: "include",
          }
        );

        if (response.ok) {
          await fetchStudents();
        }
      } catch (error) {
        console.error("Error deleting student:", error);
      }
    }
  };

  const handleToggleStudentStatus = async (student) => {
    try {
      const response = await fetch(
        `${config.API_URL}/admin/students/${student.id}/toggle-status`,
        {
          method: "PUT",
          credentials: "include",
        }
      );

      if (response.ok) {
        await fetchStudents();
      }
    } catch (error) {
      console.error("Error toggling student status:", error);
    }
  };

  const handleEditStudentFormChange = (e) => {
    const { name, value } = e.target;
    setEditStudentForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateUniversity = async () => {
    try {
      const response = await fetch(`${config.API_URL}/admin/universities`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(universityForm)
      });
      if (response.ok) {
        await fetchUniversities();
        setShowUniversityModal(false);
        setUniversityForm({ name: ""});
      }
    } catch (error) {
      console.error("Error creating university:", error);
    }
  };

  const handleUpdateUniversity = async () => {
    try {
      const response = await fetch(`${config.API_URL}/admin/universities/${selectedUniversity.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(universityForm)
      });
      if (response.ok) {
        await fetchUniversities();
        setShowUniversityModal(false);
        setSelectedUniversity(null);
      }
    } catch (error) {
      console.error("Error updating university:", error);
    }
  };

  const handleDeleteUniversity = async (id) => {
    if (window.confirm("Are you sure you want to delete this university?")) {
      try {
        const response = await fetch(`${config.API_URL}/admin/universities/${id}`, {
          method: "DELETE",
          credentials: "include"
        });
        if (response.ok) {
          await fetchUniversities();
        }
      } catch (error) {
        console.error("Error deleting university:", error);
      }
    }
  };

  const renderContent = () => {
    switch (activeSection) {
      case "users":
        return (
          <div className="table-container">
            <div className="table-header">
              <h3>User Management</h3>
              <Button
                variant="primary"
                className="mb-3"
                onClick={() => setShowCreateModal(true)}
              >
                <i className="fas fa-plus me-2"></i>
                Create New User
              </Button>
            </div>

            <Table hover className="modern-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Permissions</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>
                      <Form.Check
                        type="switch"
                        id={`admin-switch-${user.id}`}
                        label={user.is_admin ? "Admin" : "User"}
                        checked={user.is_admin}
                        onChange={(e) =>
                          handleAdminStatusChange(user.id, e.target.checked)
                        }
                        disabled={user.id === currentUser?.id}
                      />
                    </td>
                    <td>
                      {user.permissions?.map((permission) => (
                        <span key={permission} className="badge bg-info me-1">
                          {permission}
                        </span>
                      ))}
                    </td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleEditPermissions(user)}
                        disabled={user.is_admin}
                        className="me-2"
                      >
                        Edit Permissions
                      </Button>
                      {user.id !== currentUser?.id && (
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeleteUser(user.id)}
                        >
                          <i className="fas fa-trash me-1"></i>
                          Delete
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        );
      case "students":
        return (
          <div className="table-container">
            <div className="table-header">
              <h3>Student Management</h3>
            </div>

            <Table hover className="modern-table student-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Travel Document No.</th>
                  <th>Current Owner</th>
                  <th>Active</th>
                  <th className="student-actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {students.map((student) => (
                  <tr
                    key={student.id}
                    className={!student.is_active ? "inactive-row" : ""}
                  >
                    <td data-label="Name">{student.name}</td>
                    <td data-label="Travel Document No.">
                      {student.travel_doc_no}
                    </td>
                    <td data-label="Current Owner">{student.owner_name}</td>
                    <td data-label="Active">
                      <span
                        className={
                          student.is_active ? "active-badge" : "inactive-badge"
                        }
                      >
                        {student.is_active ? "Active" : "Inactive"}
                      </span>
                    </td>
                    <td data-label="Actions" className="student-actions">
                      <div className="student-action-grid">
                        <button
                          className="student-action-btn btn-edit"
                          onClick={() => handleEditStudent(student)}
                        >
                          <i className="fas fa-edit"></i>
                          <span>Edit</span>
                        </button>
                        <button
                          className={`student-action-btn ${
                            student.is_active
                              ? "btn-deactivate"
                              : "btn-activate"
                          }`}
                          onClick={() => handleToggleStudentStatus(student)}
                        >
                          <i
                            className={`fas fa-${
                              student.is_active ? "ban" : "check"
                            }`}
                          ></i>
                          <span>
                            {student.is_active ? "Deactivate" : "Activate"}
                          </span>
                        </button>
                        <button
                          className="student-action-btn btn-change-owner"
                          onClick={() => {
                            setSelectedStudent(student);
                            setShowChangeOwnerModal(true);
                          }}
                        >
                          <i className="fas fa-exchange-alt"></i>
                          <span>Change Owner</span>
                        </button>
                        <button
                          className="student-action-btn btn-delete"
                          onClick={() => handleDeleteStudent(student)}
                        >
                          <i className="fas fa-trash"></i>
                          <span>Delete</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        );
      case "universities":
        return (
          <div className="table-container">
            <div className="table-header">
              <h3>University Management</h3>
              <Button
                variant="primary"
                className="mb-3"
                onClick={() => {
                  setSelectedUniversity(null);
                  setUniversityForm({ name: ""});
                  setShowUniversityModal(true);
                }}
              >
                <i className="fas fa-plus me-2"></i>
                Add University
              </Button>
            </div>

            <Table hover className="modern-table">
              <thead>
                <tr>
                  <th>Name</th>

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {universities.map((university) => (
                  <tr key={university.id}>
                    <td>{university.name}</td>

                    <td>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="me-2"
                        onClick={() => {
                          setSelectedUniversity(university);
                          setUniversityForm(university);
                          setShowUniversityModal(true);
                        }}
                      >
                        <i className="fas fa-edit"></i>
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleDeleteUniversity(university.id)}
                      >
                        <i className="fas fa-trash"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Modal show={showUniversityModal} onHide={() => setShowUniversityModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {selectedUniversity ? "Edit University" : "Add University"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={universityForm.name}
                      onChange={(e) => setUniversityForm(prev => ({...prev, name: e.target.value}))}
                      required
                    />
                  </Form.Group>
                  
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowUniversityModal(false)}>
                  Cancel
                </Button>
                <Button 
                  variant="primary"
                  onClick={selectedUniversity ? handleUpdateUniversity : handleCreateUniversity}
                >
                  {selectedUniversity ? "Save Changes" : "Create University"}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
      case "settings":
        return (
          <div className="table-container">
            <div className="table-header">
              <h3>Settings</h3>
            </div>
            <p>System settings will be available here.</p>
          </div>
        );
      default:
        return null;
    }
  };

  if (!user?.is_admin) {
    return (
      <>
        <AppNavbar user={user} onLogout={onLogout} />
        <Container className="mt-4">
          <h2>Access Denied</h2>
          <p>You do not have permission to access this page.</p>
        </Container>
        <Footer />
      </>
    );
  }

  return (
    <>
      <AppNavbar user={user} onLogout={onLogout} />
      <div className="dashboard-container">
        <div className="admin-layout">
          <div className="admin-sidebar">
            <div className="sidebar-header">
              <h4>Admin Panel</h4>
            </div>
            <ul className="sidebar-menu">
              <li>
                <button
                  className={`sidebar-menu-item ${
                    activeSection === "users" ? "active" : ""
                  }`}
                  onClick={() => setActiveSection("users")}
                >
                  <i className="fas fa-users me-2"></i>
                  User Management
                </button>
              </li>
              <li>
                <button
                  className={`sidebar-menu-item ${
                    activeSection === "students" ? "active" : ""
                  }`}
                  onClick={() => setActiveSection("students")}
                >
                  <i className="fas fa-user-graduate me-2"></i>
                  Student Management
                </button>
              </li>
              <li>
                <button
                  className={`sidebar-menu-item ${
                    activeSection === "universities" ? "active" : ""
                  }`}
                  onClick={() => setActiveSection("universities")}
                >
                  <i className="fas fa-university me-2"></i>
                  Universities
                </button>
              </li>
              <li>
                <button
                  className={`sidebar-menu-item ${
                    activeSection === "settings" ? "active" : ""
                  }`}
                  onClick={() => setActiveSection("settings")}
                >
                  <i className="fas fa-cog me-2"></i>
                  Settings
                </button>
              </li>
            </ul>
          </div>

          <div className="admin-content">
            {loading ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              renderContent()
            )}
          </div>
        </div>

        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit User Permissions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>User: {selectedUser?.name}</p>
            <Form>
              {availablePermissions.map((permission) => (
                <Form.Check
                  key={permission.id}
                  type="checkbox"
                  id={`permission-${permission.id}`}
                  label={permission.name}
                  checked={editPermissions.includes(permission.id)}
                  onChange={() => handlePermissionChange(permission.id)}
                />
              ))}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSavePermissions}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Create New User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {createError && (
              <div className="alert alert-danger">{createError}</div>
            )}
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={createUserForm.name}
                  onChange={handleCreateFormChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={createUserForm.email}
                  onChange={handleCreateFormChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={createUserForm.password}
                  onChange={handleCreateFormChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  name="phone_number"
                  value={createUserForm.phone_number}
                  onChange={handleCreateFormChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  id="is_admin"
                  name="is_admin"
                  label="Admin User"
                  checked={createUserForm.is_admin}
                  onChange={handleCreateFormChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Permissions</Form.Label>
                {availablePermissions.map((permission) => (
                  <Form.Check
                    key={permission.id}
                    type="checkbox"
                    id={`create-permission-${permission.id}`}
                    label={permission.name}
                    checked={createUserForm.permissions.includes(permission.id)}
                    onChange={() => handleCreatePermissionChange(permission.id)}
                  />
                ))}
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowCreateModal(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleCreateUser}>
              Create User
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showChangeOwnerModal}
          onHide={() => setShowChangeOwnerModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Student Owner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Student: {selectedStudent?.name}</p>
            <p>Current Owner: {selectedStudent?.owner_name}</p>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>New Owner</Form.Label>
                <Form.Select
                  value={newOwnerId}
                  onChange={(e) => setNewOwnerId(e.target.value)}
                >
                  <option value="">Select new owner</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name} ({user.email})
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowChangeOwnerModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleChangeOwner}
              disabled={!newOwnerId}
            >
              Change Owner
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showEditStudentModal}
          onHide={() => setShowEditStudentModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Student</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={editStudentForm.name}
                  onChange={handleEditStudentFormChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Travel Document No.</Form.Label>
                <Form.Control
                  type="text"
                  name="travel_doc_no"
                  value={editStudentForm.travel_doc_no}
                  onChange={handleEditStudentFormChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Nationality</Form.Label>
                <Form.Control
                  type="text"
                  name="nationality"
                  value={editStudentForm.nationality}
                  onChange={handleEditStudentFormChange}
                  required
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowEditStudentModal(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleEditStudentSubmit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Footer />
    </>
  );
};

export default AdminPanel;
