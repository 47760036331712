import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { countries } from "../utils/countries";
import Select from "react-select";

const SearchAndFilter = ({
  filters,
  onFilterChange,
  students,
  universities,
  counselors,
  user,
}) => {
  // Get unique nationalities from current students
  const existingNationalities = [
    ...new Set(students.map((student) => student.nationality)),
  ];

  // Get country details for existing nationalities and sort by name
  const availableCountries = countries
    .filter((country) =>
      existingNationalities.includes(country.code.toUpperCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  // Get unique statuses from current students
  const availableStatuses = [
    ...new Set(
      students
        .map((student) => student.application_status)
        .filter((status) => status)
    ),
  ].sort();

  // Get unique application types from current students
  const availableApplicationTypes = [
    ...new Set(
      students.map((student) => student.application_type).filter((type) => type)
    ),
  ].sort();

  // Get unique invoice statuses from current students
  const availableInvoiceStatuses = [
    ...new Set(
      students
        .map((student) => student.invoice_status)
        .filter((status) => status)
    ),
  ].sort();

  const hasInvoiceAccess =
    user &&
    (user.is_admin ||
      (user.permissions && user.permissions.includes("invoice_access")));

  // Handle multi-select change
  const handleMultiSelectChange = (name) => (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    onFilterChange({
      target: {
        name,
        value: selectedValues,
      },
    });
  };

  // Create options for react-select
  const createOptions = (items) =>
    items.map((item) => ({
      value: item,
      label: item,
    }));

  // Create options for each filter
  const countryOptions = availableCountries.map((country) => ({
    value: country.code.toUpperCase(),
    label: country.name,
  }));

  const statusOptions = createOptions(availableStatuses);
  const applicationTypeOptions = createOptions(availableApplicationTypes);
  const invoiceStatusOptions = availableInvoiceStatuses.map((status) => ({
    value: status,
    label: status.charAt(0).toUpperCase() + status.slice(1),
  }));
  const counselorOptions = createOptions(counselors);
  const universityOptions = createOptions(universities);
  const sortOptions = [
    { value: "name", label: "Sort by Name" },
    { value: "lastUpdated", label: "Sort by Last Updated" },
    { value: "percentage", label: "Sort by Progress" },
  ];

  // Initialize filters as arrays if they're not already
  const getSelectedOptions = (options, filterValue) => {
    if (!filterValue) return [];
    const values = Array.isArray(filterValue) ? filterValue : [filterValue];
    return options.filter((option) => values.includes(option.value));
  };

  // Add reset filters handler
  const handleResetFilters = () => {
    onFilterChange({
      target: {
        name: "reset",
        value: {
          searchQuery: "",
          nationality: [],
          status: [],
          universities: [],
          counselor: [],
          invoice_status: [],
          application_type: [],
          sortBy: "name",
        },
      },
    });
  };

  return (
    <div className="search-filter-container mb-4">
      <Row className="g-3">
        <Col md={4}>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Search by name or document number"
              name="searchQuery"
              value={filters.searchQuery}
              onChange={onFilterChange}
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Select
              isMulti
              name="nationality"
              options={countryOptions}
              value={getSelectedOptions(countryOptions, filters.nationality)}
              onChange={handleMultiSelectChange("nationality")}
              placeholder="All Nationalities"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Select
              isMulti
              name="status"
              options={statusOptions}
              value={getSelectedOptions(statusOptions, filters.status)}
              onChange={handleMultiSelectChange("status")}
              placeholder="All Status"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group>
            <Select
              name="sortBy"
              options={sortOptions}
              value={sortOptions.find((option) => option.value === filters.sortBy)}
              onChange={(option) =>
                onFilterChange({
                  target: {
                    name: "sortBy",
                    value: option ? option.value : "name",
                  },
                })
              }
              placeholder="Sort by..."
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Select
              isMulti
              name="universities"
              options={universityOptions}
              value={getSelectedOptions(universityOptions, filters.universities)}
              onChange={handleMultiSelectChange("universities")}
              placeholder="Select Universities"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Select
              isMulti
              name="counselor"
              options={counselorOptions}
              value={getSelectedOptions(counselorOptions, filters.counselor)}
              onChange={handleMultiSelectChange("counselor")}
              placeholder="All Counselors"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Select
              isMulti
              name="application_type"
              options={applicationTypeOptions}
              value={getSelectedOptions(
                applicationTypeOptions,
                filters.application_type
              )}
              onChange={handleMultiSelectChange("application_type")}
              placeholder="All Application Types"
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Form.Group>
        </Col>
        {hasInvoiceAccess && (
          <Col md={2}>
            <Form.Group>
              <Select
                isMulti
                name="invoice_status"
                options={invoiceStatusOptions}
                value={getSelectedOptions(
                  invoiceStatusOptions,
                  filters.invoice_status
                )}
                onChange={handleMultiSelectChange("invoice_status")}
                placeholder="All Invoice Status"
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Form.Group>
          </Col>
        )}
        <Col md={1} className="d-flex align-items-center">
          <Button 
            variant="outline-secondary" 
            onClick={handleResetFilters}
            className="reset-filters-btn"
            title="Reset all filters"
          >
            <i className="fas fa-undo"></i>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SearchAndFilter;
