import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { countries } from '../utils/countries';
import config from "../config";

const AddStudentModal = ({ show, onHide, onStudentAdded, studentToEdit, isEditing }) => {
  const [formData, setFormData] = useState({
    name: '',
    travel_doc_no: '',
    nationality: '',
    university_id: ''  // Add university field
  });
  const [universities, setUniversities] = useState([]); // Add universities state
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  // Fetch universities when modal opens
  useEffect(() => {
    const fetchUniversities = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${config.API_URL}/admin/universities`, {
          credentials: 'include'
        });
        if (response.ok) {
          const data = await response.json();
          setUniversities(data);
        }
      } catch (error) {
        console.error('Error fetching universities:', error);
      }
      setIsLoading(false);
    };

    if (show) {
      fetchUniversities();
    }
  }, [show]);

  // Update the editing useEffect
  useEffect(() => {
    if (isEditing && studentToEdit) {
      console.log('Student to edit:', studentToEdit); // Keep debug log
      const universityId = universities.find(u => u.name === studentToEdit.university)?.id;
      
      setFormData({
        name: studentToEdit.name || '',
        travel_doc_no: studentToEdit.travel_doc_no || '',
        nationality: studentToEdit.nationality || '',
        university_id: universityId ? universityId.toString() : ''
      });
    } else if (!isEditing) {
      setFormData({
        name: '',
        travel_doc_no: '',
        nationality: '',
        university_id: ''
      });
    }
  }, [isEditing, studentToEdit, universities]); // Add universities to dependencies

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSubmit = {
        ...formData,
        nationality: formData.nationality.toUpperCase()
      };

      const url = isEditing 
        ? `${config.API_URL}/students/${studentToEdit.id}`
        : `${config.API_URL}/students`;

      const response = await fetch(url, {
        method: isEditing ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(dataToSubmit),
      });

      const data = await response.json();
      
      if (response.ok) {
        onStudentAdded(data, isEditing);
        onHide();
        setFormData({ name: '', travel_doc_no: '', nationality: '', university_id: '' });
      } else {
        const errorMessage = data.error || 'Failed to save student. Please try again.';
        console.error(isEditing ? 'Failed to update student:' : 'Failed to add student:', errorMessage);
        alert(errorMessage);
      }
    } catch (error) {
      console.error(isEditing ? 'Error updating student:' : 'Error adding student:', error);
      alert('An unexpected error occurred. Please try again.');
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const sortedCountries = [...countries].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{isEditing ? 'Edit Student' : 'Add New Student'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Student Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter student name"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Travel Document Number</Form.Label>
            <Form.Control
              type="text"
              name="travel_doc_no"
              value={formData.travel_doc_no}
              onChange={handleChange}
              placeholder="Enter travel document number"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Nationality</Form.Label>
            <Form.Select
              name="nationality"
              value={formData.nationality}
              onChange={handleChange}
              required
            >
              <option value="">Select nationality</option>
              {sortedCountries.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>University</Form.Label>
            <Form.Select
              name="university_id"
              value={formData.university_id}
              onChange={handleChange}
              required
              disabled={isLoading}
            >
              <option value="">Select university</option>
              {!isLoading && universities.map((university) => (
                <option key={university.id} value={university.id.toString()}>
                  {university.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <div className="d-flex justify-content-end gap-2">
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {isEditing ? 'Save Changes' : 'Add Student'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddStudentModal;