/**
 * Dashboard component that displays and manages student information
 * Features include student listing, filtering, status updates, and CRUD operations
 * Implements role-based access control for different user permissions
 */

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Table, Button, Modal, Dropdown } from "react-bootstrap";
import AppNavbar from "./Navbar";
import Footer from "./Footer";
import AddStudentModal from "./AddStudentModal";
import SearchAndFilter from "./SearchAndFilter";
import "../styles/Dashboard.css";
import { countries } from "../utils/countries";
import config from "../config";

/**
 * Main Dashboard component
 * @param {Object} user - Current user object containing permissions and role information
 * @param {Function} onLogout - Callback function to handle user logout
 */
const Dashboard = ({ user, onLogout }) => {
  // State management for students data and UI controls
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showProgress, setShowProgress] = useState(false);
  const [showAddStudent, setShowAddStudent] = useState(false);
  const [selectedStudentProgress, setSelectedStudentProgress] = useState([]);
  const [showEditStudent, setShowEditStudent] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    searchQuery: "",
    nationality: [],
    status: [],
    universities: [],
    counselor: [],
    invoice_status: [],
    application_type: [],
    sortBy: "name",
  });
  const [showIssues, setShowIssues] = useState(false);
  const [selectedStudentIssues, setSelectedStudentIssues] = useState([]);

  // Permission checks based on user role and permissions
  const hasEditPermission =
    user &&
    (user.is_admin ||
      (user.permissions && user.permissions.includes("edit_students")));
  const hasAddPermission =
    user &&
    (user.is_admin ||
      (user.permissions && user.permissions.includes("add_student")));
  const hasInvoiceAccess =
    user &&
    (user.is_admin ||
      (user.permissions && user.permissions.includes("invoice_access")));

  /**
   * Fetches student data from the API
   * Handles error cases and updates loading state
   */
  const fetchStudents = useCallback(async () => {
    try {
      const response = await fetch(`${config.API_URL}/students`, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setStudents(data);
      } else {
        setError("Failed to fetch students. Please try again later.");
        setStudents([]);
      }
    } catch (error) {
      console.error("Error fetching students:", error);
      setError("Failed to fetch students. Please try again later.");
      setStudents([]);
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch students data on component mount only
  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  /**
   * Converts country code to full country name
   * @param {string} countryCode - Two-letter country code
   * @returns {string} Full country name or original code if not found
   */
  const getCountryName = (countryCode) => {
    if (!countryCode) return "N/A";
    const country = countries.find(
      (c) => c.code.toUpperCase() === countryCode.toUpperCase()
    );
    return country ? country.name : countryCode;
  };

  /**
   * Handles bulk status update for all students
   */
  const handleUpdateStatus = async () => {
    setLoading(true);
    setError(null); // Clear any previous errors

    try {
      const studentsToUpdate = students.map((student) => ({
        id: student.id,
        travel_doc_no: student.travel_doc_no,
        nationality: student.nationality.toUpperCase(),
      }));

      const response = await fetch(`${config.API_URL}/check-status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ students: studentsToUpdate }),
      });

      if (response.ok) {
        await fetchStudents();
        setError(null); // Clear error on success
      } else {
        const errorData = await response.json();
        setError(errorData.error || "Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      setError("Network error while updating status");
    } finally {
      setLoading(false);
    }
  };

  /**
   * Updates filter state based on user input
   * @param {Event} e - Change event from filter inputs
   */
  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    if (name === "reset") {
      setFilters(value);
    } else {
      setFilters((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  /**
   * Shows progress modal for selected student
   * @param {Object} student - Student object containing progress information
   */
  const handleShowProgress = (student) => {
    setSelectedStudentProgress(student.application_progress || []);
    setShowProgress(true);
  };

  /**
   * Formats date-time string to localized format with date and time on separate lines
   * @param {string} dateTimeStr - ISO date-time string
   * @returns {string} Formatted date-time string
   */
  const formatDateTime = (dateTimeStr) => {
    if (!dateTimeStr) return "N/A";
    const date = new Date(dateTimeStr);
    const dateStr = date.toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
    });
    const timeStr = date.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return (
      <div className="date-time-container">
        <div className="date">{dateStr}</div>
        <div className="time">{timeStr}</div>
      </div>
    );
  };

  /**
   * Handles student edit action with permission check
   * @param {Object} student - Student object to be edited
   */
  const handleEditStudent = (student) => {
    if (user.is_admin || student.user_id === user.id) {
      setSelectedStudent(student);
      setShowEditStudent(true);
    } else {
      setError("You don't have permission to edit this student");
    }
  };

  /**
   * Handles the student update after add/edit
   * @param {Object} updatedStudent - The updated or new student data
   * @param {boolean} isEditing - Whether this is an edit or new student
   */
  const handleStudentUpdate = (updatedStudent, isEditing) => {
    if (isEditing) {
      // Update existing student in the list
      setStudents((prevStudents) =>
        prevStudents.map((student) =>
          student.id === updatedStudent.id ? updatedStudent : student
        )
      );
    } else {
      // Add new student to the list using the exact data from the response
      setStudents((prevStudents) => [...prevStudents, updatedStudent]);
    }
  };

  /**
   * Memoized filtered and sorted students list
   * Applies search, nationality, and status filters
   * Sorts based on selected sort criteria
   */
  const filteredStudents = useMemo(() => {
    return students
      .filter((student) => {
        // Filter by user permissions
        if (
          !user.is_admin &&
          !user.permissions?.includes("view_all_students") &&
          student.user_id !== user.id
        ) {
          return false;
        }

        // Apply search filter
        const matchesSearch = filters.searchQuery
          ? student.name
              ?.toLowerCase()
              .includes(filters.searchQuery.toLowerCase()) ||
            student.travel_doc_no
              ?.toLowerCase()
              .includes(filters.searchQuery.toLowerCase())
          : true;

        // Apply nationality filter
        const matchesNationality =
          filters.nationality.length === 0
            ? true
            : filters.nationality.includes(student.nationality.toUpperCase());

        // Apply university filter
        const matchesUniversity =
          filters.universities.length === 0
            ? true
            : filters.universities.includes(student.university);

        // Apply status filter
        const matchesStatus =
          filters.status.length === 0
            ? true
            : filters.status.includes(student.application_status);

        // Apply counselor filter
        const matchesCounselor =
          filters.counselor.length === 0
            ? true
            : filters.counselor.includes(student.user_name);

        // Apply invoice status filter
        const matchesInvoiceStatus =
          filters.invoice_status.length === 0
            ? true
            : filters.invoice_status.includes(student.invoice_status);

        // Apply application type filter
        const matchesApplicationType =
          filters.application_type.length === 0
            ? true
            : filters.application_type.includes(student.application_type);

        return (
          matchesSearch &&
          matchesNationality &&
          matchesStatus &&
          matchesUniversity &&
          matchesCounselor &&
          matchesInvoiceStatus &&
          matchesApplicationType
        );
      })
      .sort((a, b) => {
        // First sort by update status (unviewed updates at top)
        const aLastUpdated = a.last_updated ? new Date(a.last_updated) : null;
        const aLastViewed = a.last_viewed ? new Date(a.last_viewed) : null;
        const bLastUpdated = b.last_updated ? new Date(b.last_updated) : null;
        const bLastViewed = b.last_viewed ? new Date(b.last_viewed) : null;

        const aHasUpdate =
          aLastUpdated &&
          (!aLastViewed || aLastUpdated.getTime() > aLastViewed.getTime());
        const bHasUpdate =
          bLastUpdated &&
          (!bLastViewed || bLastUpdated.getTime() > bLastViewed.getTime());

        if (aHasUpdate && !bHasUpdate) return -1;
        if (!aHasUpdate && bHasUpdate) return 1;

        // Then apply the selected sort criteria
        switch (filters.sortBy) {
          case "name":
            return (a.name || "").localeCompare(b.name || "");
          case "lastUpdated":
            return (
              (bLastUpdated?.getTime() || 0) - (aLastUpdated?.getTime() || 0)
            );
          case "percentage":
            return (
              (parseInt(b.percentage) || 0) - (parseInt(a.percentage) || 0)
            );
          default:
            return 0;
        }
      });
  }, [students, filters, user]);

  // Add this before the return statement in Dashboard component
  const uniqueUniversities = useMemo(() => {
    return [...new Set(students.map((s) => s.university))]
      .filter(Boolean)
      .sort();
  }, [students]);

  const uniqueCounselors = useMemo(() => {
    return [...new Set(students.map((s) => s.user_name))]
      .filter(Boolean)
      .sort();
  }, [students]);

  // Add function to handle invoice status update
  const handleInvoiceStatusUpdate = async (student, newStatus) => {
    try {
      const response = await fetch(
        `${config.API_URL}/students/${student.id}/invoice-status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ status: newStatus }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to update invoice status");
      }

      // Update local state instead of refetching
      setStudents((prevStudents) =>
        prevStudents.map((s) =>
          s.id === student.id ? { ...s, invoice_status: newStatus } : s
        )
      );
    } catch (error) {
      console.error("Error updating invoice status:", error);
      setError(error.message || "Failed to update invoice status");
    }
  };

  return (
    <>
      <AppNavbar user={user} onLogout={onLogout} />
      <div className="dashboard-container">
        {loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="table-container">
            {/* Dashboard header with title and action buttons */}
            <div className="table-header">
              <h3>
                Student List{" "}
                {!user.is_admin &&
                  !user.permissions?.includes("view_all_students") &&
                  "(Your Students)"}
              </h3>
              {error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  {error}
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setError(null)}
                  ></button>
                </div>
              )}
              <div className="button-group">
                {/* Status update button */}
                <Button
                  variant="success"
                  className="update-status-btn me-2"
                  onClick={handleUpdateStatus}
                  disabled={loading}
                >
                  <i className="fas fa-sync-alt me-2"></i>
                  {loading ? "Updating..." : "Update Status"}
                </Button>
                {/* Add student button - only shown if user has permission */}
                {hasAddPermission && (
                  <Button
                    variant="primary"
                    className="add-student-btn"
                    onClick={() => setShowAddStudent(true)}
                  >
                    <i className="fas fa-plus me-2"></i>
                    Add New Student
                  </Button>
                )}
              </div>
            </div>

            {/* Search and filter component */}
            <SearchAndFilter
              filters={filters}
              onFilterChange={handleFilterChange}
              students={students}
              universities={uniqueUniversities}
              counselors={uniqueCounselors}
              user={user}
            />

            {/* Students data table */}
            <Table hover className="modern-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>University</th>
                  <th>Passport No.</th>
                  <th>Counselor</th>
                  <th>Status</th>
                  <th>Progress</th>
                  {hasInvoiceAccess && <th>Invoice</th>}
                  <th>Last Updated</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredStudents.map((student, index, array) => {
                  // Parse dates and ensure they're in the same format
                  const lastUpdated = student.last_updated
                    ? new Date(student.last_updated)
                    : null;
                  const lastViewed = student.last_viewed
                    ? new Date(student.last_viewed)
                    : null;

                  // Compare timestamps
                  const hasUpdate =
                    lastUpdated &&
                    (!lastViewed ||
                      lastUpdated.getTime() > lastViewed.getTime());
                  const prevStudent = index > 0 ? array[index - 1] : null;
                  const prevHasUpdate =
                    prevStudent &&
                    prevStudent.last_updated &&
                    (!prevStudent.last_viewed ||
                      new Date(prevStudent.last_updated).getTime() >
                        new Date(prevStudent.last_viewed).getTime());

                  // Add section header when transitioning from updated to non-updated students
                  const showSeparator = hasUpdate !== prevHasUpdate;

                  return (
                    <React.Fragment key={index}>
                      {showSeparator && (
                        <tr className="section-header">
                          <td
                            colSpan={hasInvoiceAccess ? 9 : 8}
                            className="section-header-cell"
                          >
                            {hasUpdate ? "Recent Updates" : "No Recent Updates"}
                          </td>
                        </tr>
                      )}
                      <tr className={hasUpdate ? "unviewed-update" : ""}>
                        <td data-label="Name">
                          <div className="d-flex align-items-center gap-2">
                            {student.name}
                            <span className="nationality-badge">
                              {getCountryName(student.nationality)}
                            </span>
                          </div>
                        </td>
                        <td data-label="University">{student.university}</td>
                        <td data-label="Travel Document No.">
                          {student.travel_doc_no}
                        </td>
                        <td data-label="Counselor">{student.user_name}</td>
                        <td data-label="Status">
                          <span
                            className={`status-badge status-${
                              student.application_status
                                ? student.application_status
                                    .toLowerCase()
                                    .replace(/\s+/g, "-")
                                : "unknown"
                            }`}
                          >
                            {student.application_status || "Unknown"}
                          </span>
                        </td>
                        <td data-label="Progress">
                          <div className="mobile-progress-container">
                            <div className="progress-text">
                              {student.percentage || "0%"}
                            </div>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                style={{
                                  width: student.percentage
                                    ? student.percentage
                                    : "0%",
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        {hasInvoiceAccess && (
                          <td data-label="Invoice">
                            <Dropdown>
                              <Dropdown.Toggle
                                className={`invoice-status-${
                                  student.invoice_status || "pending"
                                }`}
                                size="sm"
                              >
                                {(student.invoice_status || "pending")
                                  .charAt(0)
                                  .toUpperCase() +
                                  (student.invoice_status || "pending").slice(
                                    1
                                  )}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    handleInvoiceStatusUpdate(
                                      student,
                                      "pending"
                                    )
                                  }
                                >
                                  Pending
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    handleInvoiceStatusUpdate(
                                      student,
                                      "invoiced"
                                    )
                                  }
                                >
                                  Invoiced
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    handleInvoiceStatusUpdate(student, "paid")
                                  }
                                >
                                  Paid
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        )}
                        <td data-label="Last Updated">
                          {student.last_updated
                            ? formatDateTime(student.last_updated)
                            : "N/A"}
                        </td>
                        <td>
                          <div className="action-buttons">
                            {hasEditPermission && (
                              <Button
                                variant="primary"
                                size="sm"
                                className="me-2"
                                onClick={() => handleEditStudent(student)}
                              >
                                <i className="fas fa-edit me-1"></i>
                                Edit
                              </Button>
                            )}
                            <Button
                              variant="info"
                              size="sm"
                              className="me-2"
                              onClick={() => handleShowProgress(student)}
                            >
                              <i className="fas fa-history me-1"></i>
                              Progress
                            </Button>
                            <Button
                              variant="warning"
                              size="sm"
                              onClick={() => {
                                setSelectedStudentIssues(
                                  student.application_issues || []
                                );
                                setShowIssues(true);
                              }}
                            >
                              <i className="fas fa-exclamation-circle me-1"></i>
                              View Issues
                            </Button>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}

        <Modal
          show={showProgress}
          onHide={() => setShowProgress(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Application Progress History</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                {selectedStudentProgress.map((progress, index) => (
                  <tr key={index}>
                    <td>{progress.date}</td>
                    <td>{progress.status}</td>
                    <td>{progress.remark}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>

        <AddStudentModal
          show={showAddStudent}
          onHide={() => setShowAddStudent(false)}
          onStudentAdded={handleStudentUpdate}
        />

        <AddStudentModal
          show={showEditStudent}
          onHide={() => {
            setShowEditStudent(false);
            setSelectedStudent(null);
          }}
          onStudentAdded={handleStudentUpdate}
          studentToEdit={selectedStudent}
          isEditing={true}
        />

        <IssuesModal
          show={showIssues}
          onHide={() => setShowIssues(false)}
          issues={selectedStudentIssues}
        />
      </div>
      <Footer />
    </>
  );
};

/**
 * Modal component for displaying student issues
 * @param {Object} props - Component props
 * @param {boolean} props.show - Controls modal visibility
 * @param {Function} props.onHide - Callback to close modal
 * @param {Array} props.issues - Array of student issues
 */
const IssuesModal = ({ show, onHide, issues = [] }) => {
  const [expandedIssues, setExpandedIssues] = useState({});

  const toggleIssue = (index) => {
    setExpandedIssues((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Application Issues</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Array.isArray(issues) && issues.length > 0 ? (
          issues.map((issue, index) => (
            <div key={index} className="issue-card mb-4 p-3 border rounded">
              <div
                className="issue-header d-flex justify-content-between align-items-center"
                onClick={() => toggleIssue(index)}
                style={{ cursor: "pointer" }}
              >
                <h5 className="mb-0">
                  {issue["No."]} {issue.Issue}
                </h5>
                <span className="collapse-icon">
                  <i
                    className={`fas fa-chevron-down ${
                      expandedIssues[index] ? "rotated" : ""
                    }`}
                  ></i>
                </span>
              </div>
              <div
                className={`issue-content mt-3 ${
                  expandedIssues[index] ? "show" : ""
                }`}
              >
                {issue.Statuses.map((status, statusIndex) => (
                  <div
                    key={statusIndex}
                    className="status-update mb-3 ps-3 border-start"
                  >
                    <div className="d-flex justify-content-between align-items-start mb-2">
                      <span
                        className={`badge ${
                          status.Status === "Open"
                            ? "bg-danger"
                            : status.Status === "Pending"
                            ? "bg-warning"
                            : "bg-success"
                        }`}
                      >
                        {status.Status}
                      </span>
                      <small className="text-muted">
                        {status["Updated At"]}
                      </small>
                    </div>
                    <p className="mb-0" style={{ whiteSpace: "pre-line" }}>
                      {status.Comment}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-muted">No issues found</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Dashboard;
