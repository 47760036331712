/**
 * Main application component that handles routing and authentication state
 * Uses React Router for navigation and maintains user authentication status
 */

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import AdminPanel from "./components/AdminPanel";
import Profile from "./components/Profile";
import "./styles/App.css";
import config from "./config";

function App() {
  // Authentication and user state management
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Check authentication status when component mounts
  useEffect(() => {
    checkAuthStatus();
  }, []);

  /**
   * Verifies if user is already authenticated by checking with the backend
   * Makes a request to /api/check-auth endpoint with credentials
   */
  const checkAuthStatus = async () => {
    try {
      const response = await fetch(`${config.API_URL}/check-auth`, {
        credentials: "include",
      });

      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
        setIsLoggedIn(true);
      }
    } catch (error) {
      // Error is silently handled as user is not authenticated
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handles successful login by updating user state
   * @param {Object} userData - User information received after successful login
   */
  const handleLogin = async (userData) => {
    setUser(userData);
    setIsLoggedIn(true);
    await new Promise((resolve) => setTimeout(resolve, 0));
  };

  /**
   * Handles user logout by making request to logout endpoint
   * Clears user data and authentication state on success
   */
  const handleLogout = async () => {
    try {
      const response = await fetch(`${config.API_URL}/logout`, {
        method: "POST",
        credentials: "include",
      });

      if (response.ok) {
        setUser(null);
        setIsLoggedIn(false);
      }
    } catch (error) {
      // Error is silently handled as user remains logged in
    }
  };

  // Show loading spinner while checking authentication status
  if (isLoading) {
    return <div className="loading-spinner">Loading...</div>;
  }

  return (
    <Router>
      <div className="app-container">
        {/* Conditional rendering based on authentication status */}
        {isLoggedIn ? (
          <Routes>
            {/* Main dashboard route */}
            <Route
              path="/"
              element={<Dashboard user={user} onLogout={handleLogout} />}
            />
            {/* Protected admin route - only accessible to admin users */}
            <Route
              path="/admin"
              element={
                user?.is_admin ? (
                  <AdminPanel user={user} onLogout={handleLogout} />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            {/* User profile route */}
            <Route
              path="/profile"
              element={<Profile user={user} onLogout={handleLogout} />}
            />
            {/* Redirect all unknown routes to dashboard */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        ) : (
          // Show login component if user is not authenticated
          <Login onLogin={handleLogin} />
        )}
      </div>
    </Router>
  );
}

export default App;
